var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('h3',[_vm._v("Employee Information")]),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"First Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'first_name',
                {
                  rules: [{ required: true, message: 'Please enter  name' }],
                } ]),expression:"[\n                'first_name',\n                {\n                  rules: [{ required: true, message: 'Please enter  name' }],\n                },\n              ]"}],attrs:{"placeholder":"First Name"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Last Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'last_name',
                {
                  rules: [
                    {
                      required: true,
                      message: 'please enter your last name',
                    } ],
                } ]),expression:"[\n                'last_name',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'please enter your last name',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"last name"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'email',
                {
                  rules: [{ required: true, message: 'please enter email' }],
                } ]),expression:"[\n                'email',\n                {\n                  rules: [{ required: true, message: 'please enter email' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"email"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Phone Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'phone_number',
                {
                  rules: [{ required: true, message: 'please enter phone' }],
                } ]),expression:"[\n                'phone_number',\n                {\n                  rules: [{ required: true, message: 'please enter phone' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"phone"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"National ID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'national_id',
                {
                  rules: [{ required: true, message: 'Field is required' }],
                } ]),expression:"[\n                'national_id',\n                {\n                  rules: [{ required: true, message: 'Field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"national_id"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"KRA PIN"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'kra_pin',
                {
                  rules: [{ required: true, message: 'Field is required' }],
                } ]),expression:"[\n                'kra_pin',\n                {\n                  rules: [{ required: true, message: 'Field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"KRA PIN"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"NSSF NUMBER"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'nssf_number',
                {
                  rules: [{ required: true, message: 'Field is required' }],
                } ]),expression:"[\n                'nssf_number',\n                {\n                  rules: [{ required: true, message: 'Field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"nssf number"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"NHIF NUMBER"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'nhif_number',
                {
                  rules: [{ required: true, message: 'Field is required' }],
                } ]),expression:"[\n                'nhif_number',\n                {\n                  rules: [{ required: true, message: 'Field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"NHIF"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Department"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'department',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a department',
                    } ],
                } ]),expression:"[\n                'department',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select a department',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"department"},on:{"change":_vm.handleDepartmentChange}},_vm._l((_vm.client.departments),function(department){return _c('a-select-option',{key:department.department_name,attrs:{"value":department.department_name}},[_vm._v(" "+_vm._s(department.department_name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Designation"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'designation',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a designation',
                    } ],
                } ]),expression:"[\n                'designation',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select a designation',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"select department"}},_vm._l((_vm.designations),function(designation){return _c('a-select-option',{key:designation.name,attrs:{"value":designation.name}},[_vm._v(" "+_vm._s(designation.name)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Basic Pay"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'basic_pay',
                {
                  rules: [{ required: true, message: 'please enter amount' }],
                } ]),expression:"[\n                'basic_pay',\n                {\n                  rules: [{ required: true, message: 'please enter amount' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Basic Pay","type":"number"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Date of Birth"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'date_of_birth',
                {
                  initialValue: _vm.date,
                  rules: [{ required: true, message: 'Field is required' }],
                } ]),expression:"[\n                'date_of_birth',\n                {\n                  initialValue: date,\n                  rules: [{ required: true, message: 'Field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Date of Birth"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Bank Name"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'bank_name',
                {
                  rules: [
                    { required: true, message: 'Please choose the bank' } ],
                } ]),expression:"[\n                'bank_name',\n                {\n                  rules: [\n                    { required: true, message: 'Please choose the bank' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please choose the bank"},on:{"change":_vm.handleBankChange}},_vm._l((_vm.bankNames),function(bank){return _c('a-select-option',{key:bank,attrs:{"value":bank}},[_vm._v(_vm._s(bank)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Branch Name"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'bank_branch',
                {
                  rules: [{ required: true, message: 'please enter branch' }],
                } ]),expression:"[\n                'bank_branch',\n                {\n                  rules: [{ required: true, message: 'please enter branch' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Branch Name"}},_vm._l((_vm.branches),function(branch){return _c('a-select-option',{key:branch.id,attrs:{"value":branch.BranchName}},[_vm._v(_vm._s(branch.BranchName)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Account Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'account_name',
                {
                  rules: [
                    { required: true, message: 'please enter account' } ],
                } ]),expression:"[\n                'account_name',\n                {\n                  rules: [\n                    { required: true, message: 'please enter account' },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"account Name"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Account Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'account_number',
                {
                  rules: [
                    {
                      required: true,
                      message: 'please enter account number',
                    } ],
                } ]),expression:"[\n                'account_number',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'please enter account number',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"account number","type":"number"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Gender"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'gender',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select',
                    } ],
                } ]),expression:"[\n                'gender',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select',\n                    },\n                  ],\n                },\n              ]"}]},[_c('a-select-option',{attrs:{"value":"male"}},[_vm._v(" Male ")]),_c('a-select-option',{attrs:{"value":"female"}},[_vm._v("Female")])],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Status"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select employee status',
                    } ],
                } ]),expression:"[\n                'status',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select employee status',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Status"}},[_c('a-select-option',{attrs:{"value":"active"}},[_vm._v(" Active ")]),_c('a-select-option',{attrs:{"value":"inactive"}},[_vm._v("Inactive")])],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Date Of Appointment"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'date_of_appointment',
                {
                  rules: [{ required: true, message: 'Field is required' }],
                } ]),expression:"[\n                'date_of_appointment',\n                {\n                  rules: [{ required: true, message: 'Field is required' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Date of Appointment"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Contract Type"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'contract_type',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select contract type',
                    } ],
                } ]),expression:"[\n                'contract_type',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select contract type',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"contract type"}},[_c('a-select-option',{attrs:{"value":"permanet"}},[_vm._v(" Permanent ")]),_c('a-select-option',{attrs:{"value":"temporary"}},[_vm._v("Temporary")]),_c('a-select-option',{attrs:{"value":"casual"}},[_vm._v("Casual")])],1)],1)],1)],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","block":""}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }